import Head from "next/head";
import React from "react";
import { useSiteData } from "../providers/SiteDataProvider";

export const MetaHeader: React.FC = () => {
  const { getAbsoluteUrl, currentLanguage, page, getLocalizedLinkForPage } =
    useSiteData();

  const pageTitle = page.pageTitle?.length
    ? page.pageTitle.replace(/&apos;/gm, "'") +
      " - Messe Frankfurt Salespartner"
    : "Messe Frankfurt Salespartner";

  const metaItems: Array<React.ReactElement> = [];

  metaItems.push(
    <link
      rel="canonical"
      key={"canonical"}
      href={getAbsoluteUrl(currentLanguage, page)}
    />
  );

  if (page.translatedSlugs) {
    metaItems.push(
      <link
        rel="alternate"
        href={getAbsoluteUrl(currentLanguage, page)}
        hrefLang={currentLanguage}
        key={"alt-default"}
      />
    );
    Object.entries(page.translatedSlugs).forEach(([altLang]) => {
      metaItems.push(
        <link
          rel="alternate"
          href={getLocalizedLinkForPage(page, altLang)}
          hrefLang={altLang}
          key={`alt-${altLang}`}
        />
      );
    });
  }

  let description = "";
  if (page.description) {
    description = page.description + ": ";
  }
  if (page.intro) {
    description += page.intro;
  }
  if (description.length > 1) {
    metaItems.push(
      <meta key="description" name="description" content={description} />
    );
    metaItems.push(
      <meta key="og-description" name="og:description" content={description} />
    );
    metaItems.push(
      <meta
        key="twitter-description"
        name="twitter:description"
        content={description}
      />
    );
  }

  if (page.pageTitle) {
    metaItems.push(<meta key="og-title" name="og:title" content={pageTitle} />);
    metaItems.push(
      <meta key="twitter-title" name="twitter:title" content={pageTitle} />
    );
  }

  return (
    <Head>
      <title>{pageTitle}</title>
      {metaItems}
      <script
        defer
        data-domain="salespartner.messefrankfurt.com"
        src="https://plausible.io/js/script.js"
      ></script>
    </Head>
  );
};
