import { useSiteData } from '../providers/SiteDataProvider';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useUpdateEffect } from 'react-use';

type Props = {
    show?: boolean;
    onClose: () => void;
};
export const CookieBanner: React.FC<Props> = ({ show, onClose }) => {
    const defaultClassName =
        'm-notification-layer m-notification-layer--secondary m-notification-layer--text-only';
    const { getSpecialLink, t, cookiesAllowed } = useSiteData();
    const [className, setClassName] = useState(defaultClassName);
    const privacyLink = getSpecialLink('Privacy');

    useEffect(() => {
        if (!cookiesAllowed) {
            setClassName(`${defaultClassName} m-notification-layer--open`);
        } else {
            setClassName(defaultClassName);
        }
    }, [cookiesAllowed]);

    return (
        <div
            className={className}
            data-t-name="NotificationLayer"
            data-t-decorator="CookieLayer"
            data-auto-open="true"
            data-auto-open-delay="0"
            data-t-id="43"
        >
            <div className="container">
                <button
                    className="m-notification-layer__close js-notification-layer__close"
                    onClick={onClose}
                >
                    <span className="icon icon-close">
                        <span className="sr-only" onClick={onClose}>
                            Close
                        </span>
                    </span>
                </button>
                <div className="m-notification-layer__content">
                    <div className="m-notification-layer__content-text">
                        <p>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'COMPANYNAME uses cookies to provide you the best possible browsing experience. By using our services, you consent to our use of cookies.',
                                    ),
                                }}
                            />
                            &nbsp;
                            {!privacyLink ? null : (
                                <Link href={privacyLink.href}>
                                    <a
                                        dangerouslySetInnerHTML={{
                                            __html: t('More information'),
                                        }}
                                        target="_blank"
                                        rel="noreferrer"
                                    />
                                </Link>
                            )}
                            &nbsp;
                            <br />
                        </p>
                    </div>
                    <div className="m-notification-layer__action">
                        <button
                            className="btn btn-primary btn-inverted js-notification-layer__close"
                            onClick={onClose}
                        >
                            {t('Close')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
